<template>
  <a class="poi-special-link" :href="data.url" target="virteum_special">
          <div class="poi-special-icon">
            <!-- Image links need to be linked dynamically -->
            <img :src="data.image" alt="">
          </div>
          {{ data.text }}
        </a>
</template>

<script>
export default {
    props: ["data"],
}
</script>

<style>
  /* Special Links */
.poi-special-link{
  margin-bottom: 1rem;
  font-size: 1.0rem;
  font-weight: 600;
  border-radius: 0.625rem;
  background-color: var(--co-accent);
  color: var(--co-light);
  /* text-transform: uppercase; */
  display: flex;
  align-items: center;
  gap: 0.875rem;
  padding: 0.875rem;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
}
.poi-special-icon {
  display: flex;
  align-items: center;
}
.poi-special-icon > img {
  object-fit: cover;
  border-radius: 0.25rem;
  width: 2.375rem;
  height: 2.375rem;
}
</style>