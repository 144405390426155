import {
    createStore
} from 'vuex'
import data from './data';


export default createStore({
    modules: {
        data
    }
});