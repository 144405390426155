<template>
  <a class="poi-link" :href="url" target="_parent">
          Lesen Sie den kompletten Beitrag
        </a>
</template>

<script>
export default {
    props: ["url"],
}
</script>

<style>
 .poi-link{
    font-size: 1.0rem;
  color: var(--co-accent);
  /* text-transform: uppercase; */
  text-align: left;
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 2.0rem;
 }
</style>