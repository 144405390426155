<template>
  <a class="poi-cat-link" :href="data.url" target="_parent" :style="(data.image)?'background-image:url('+data.image+')':''">
    <!-- <div class="poi-cat-link-icon">
      <img :src="data.image" alt=""> 
    </div> -->
    <div class="poi-cat-link-text">
      {{ data.title }}
    </div>
  </a>
</template>

<script>
export default {
  props: ["data"],
}
</script>

<style>
/* Kategorie Links */
.poi-narratives-title {
  font-size: 1.5rem;
  font-weight: 600;
  /* text-transform: uppercase; */
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.poi-cat-link {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--co-light);
  background-color: var(--co-dark);
  /* text-transform: uppercase; */
  display: flex;
  align-items: flex-end;
  gap: 1.5rem;
  /* padding-bottom: 1.25rem; */
  margin-bottom: 1rem;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  height: 10.625rem;

  background: linear-gradient(0deg, rgba(48, 48, 48, 0.6) 2.04%, rgba(70, 70, 70, 0) 82.11%);
  mix-blend-mode: multiply;
  box-shadow: 0px 7.45098px 44.7059px -22.3529px rgba(0, 0, 0, 0.75);
  border-radius: 25px;
  background-position: 50% 50%;
  background-size: cover;

}

.poi-cat-link-text {
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.poi-cat-link-icon {
  display: flex;
  align-items: center;
}

.poi-cat-link-icon>img {
  object-fit: cover;
  border-radius: 1.5rem;
  width: 3rem;
  height: 3rem;
}
</style>