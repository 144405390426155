import axios from "axios";
import _ from "lodash";

const state = {
    pois : [],
    narratives:[],
    activePoi:null
}

const getters = {
    pois: (state) => state.pois,
    narratives: (state) => state.narratives,
    narrativeById: (state) => (id) => {
        return state.narratives.find(n => n.id === id)
    },
    activePoi:(state)=>state.activePoi,
    poiById:(state) => (pid) =>{
        var poi = _.find(state.pois, {
            items: [{
                id: parseInt(pid)
            }]
        });
        return poi;
    }
}

const actions = {
    async loadPois({
        commit
    }) {

        const res = await axios.get('data/content.json');
        try {
            if (res.data.pois) {
                commit('setPois', res.data.pois );
            }
            if(res.data.narratives){
                commit('setNarratives',res.data.narratives);
            }
        } catch (err) {
            //
            console.log("error loading content details " + err);
        }

    },
    setActivePoi({commit},poi){
        commit('setActivePoi',poi);
    }
}

const mutations = {
    setPois: (state, data) => {
        state.pois = data;
    },
    setActivePoi:(state,data)=>{
        state.activePoi = data
    },
    setNarratives: (state, data) => {
        state.narratives = data;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}