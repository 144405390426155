<template>
  <div class="poi-copy" v-html="data">
  </div>
</template>

<script>
export default {
  props: ["data"],
};
</script>

<style>
.poi-copy{
  
}
</style>
