<template>
  <div class="poi-detailimage-container" v-if="data.file">
    
    <img :src="data.file" alt=""  @mousedown.stop="null">
    <div class="poi-image-caption">
        {{ data.caption }}
    </div>
    <div class="poi-image-source">
        Quelle: {{ data.source }}
    </div>
  </div>
</template>

<script>
export default {
    props: ["data"],
    methods:{
    }
}
</script>

<style>
.poi-detailimage-container{
    user-select: none;
}
.poi-detailimage-container > img {
    user-select: none;
    width: 100%;
}
.poi-image-caption{
    font-size: 1.2rem;
    font-weight: 900;
    color: var(--co-mid);
}
.poi-image-source{
    font-size: 0.6rem;
    color: var(--co-mid);
}
</style>