<template>
  <transition name="vc-trans" :duration="{ enter: 750, leave: 150 }">
    <div class="poi-container" v-if="poiVisible">
      <!-- Close icon -->
      <div class="poi-close-icon" @click.stop="closePoi">
        <img src="@/assets/close-icon.svg" alt="" />
      </div>
      <div class="top-spacer"></div>
      <!-- Top fader -->
      <!-- <div class="poi-detail-fader"></div> -->
      <div class="poi-text-container">
        <!-- Top image icon -->
      <div class="poi-image-container">
        <img
          :src="activePoi.image"
          @error="imgErrorHandler"
          @mousedown.stop="null"
          alt=""
          class="poi-image"
          v-if="activePoi.image"
        />
        <img src="@/assets/img_start.png" alt="" class="poi-image" v-else />
      </div>
        <h1 class="poi-inline">{{ activePoi.title }}</h1>
        <PoiDetailItemCopy :data="activePoi.description" class="poi-inline"></PoiDetailItemCopy>
        <PoiDetailItemLink :url="activePoi.url" class="poi-inline"></PoiDetailItemLink>
        <PoiDetailItemSpeciallink
          v-for="e in activePoi.highlights"
          :key="e"
          :data="e"
          class="poi-inline"
        ></PoiDetailItemSpeciallink>
        <PoiDetailItemImage :data="activePoi.photo" class="poi-inline"></PoiDetailItemImage>
        <div class="bg-light-grey" v-if="activePoi.narratives && (activePoi.narratives.length > 0)">
          <div class="poi-narratives-title poi-inline">Zu den Themen</div>
          <PoiDetailItemCatlink
            v-for="n in activePoi.narratives"
            :key="n"
            :data="narrativeById(n)"
            class="poi-inline"
          ></PoiDetailItemCatlink>
      </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PoiDetailItemCopy from "../poi/PoiDetailItemCopy.vue";
import PoiDetailItemLink from "../poi/PoiDetailItemLink.vue";
import PoiDetailItemSpeciallink from "../poi/PoiDetailItemSpeciallink.vue";
import PoiDetailItemImage from "../poi/PoiDetailItemImage.vue";
import PoiDetailItemCatlink from "../poi/PoiDetailItemCatlink.vue";

export default {
  methods: {
    ...mapActions(["setActivePoi"]),
    closePoi() {
      this.setActivePoi(null);
    },
    imgErrorHandler(event) {
      event.target.src = "/img/img_start.png";
    },
  },
  computed: {
    ...mapGetters(["activePoi","narrativeById"]),
    poiVisible() {
      return this.activePoi !== null;
    },
    poiCopy() {
      return {
        text: this.activePoi.description,
      };
    },
    poiLink() {
      return {};
    },
  },
  components: {
    PoiDetailItemCopy,
    PoiDetailItemLink,
    PoiDetailItemSpeciallink,
    PoiDetailItemImage,
    PoiDetailItemCatlink,
  },
};
</script>

<style>
.poi-container {
  /* background-color: var(--co-light);
  color: var(--co-dark); */
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: min(35.75rem, 99%);
  min-height: 15em;
  background-color: var(--co-light, #fff);
  border-radius: 2.5rem;
}

.poi-close-icon {
  position: absolute;
  top: 2.375rem;
  right: 2.25rem;
  z-index: 3;
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
  background-color: var(--co-light-grey);
  padding: 0.78125rem;
  border-radius: 1.40625rem;
}
.poi-detail-fader {
  position: absolute;
  top: 4.25rem;
  width: 100%;
  height: 4rem;
  background: linear-gradient(180deg, #ffff 0%, #fffd 50%, #fff0 100%);
  z-index: 2;
  margin: 0;
}
.poi-image {
  object-fit: cover;
  border-radius: 1.5625rem;
  width: 12.75rem;
  height: 12.75rem;
  /* margin-top: 2.375rem; */
  margin-left: 2.25rem;
}
.poi-image-container {
  z-index: 3;
}
.top-spacer{
  min-height: 2.5rem;
}
.poi-text-container {
  color: var(--co-dark, #000);
  z-index: 0;
  font-size: 1.125rem;
  line-height: 1.8rem;
  overflow-y: auto;
  overflow-x: hidden;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5em;
}
/* .poi-text-container > * {
  margin-inline: 2.25rem;
} */
.poi-inline {
  margin-inline: 2.25rem;
}
.poi-text-container > h1 {
  line-height: 2.5rem;
  margin-bottom: 1.5rem;
}
.poi-copy {
  line-height: 1.75rem;
  margin-bottom: 1.5rem;
}
.bg-light-grey{
  background-color: var(--co-light-grey);
  /* margin-inline:0rem !important; */
  border-radius: 0 0 1.5625rem 1.5625rem;
}

@media screen and (max-height: 500px) {
  .poi-image {
  border-radius: 1.25rem;
  width: 6.375rem;
  height: 6.375rem;
  /* margin-top: 2.375rem;
  margin-left: 2.25rem; */
}
}


/* Transition */
.vc-trans-enter-active,
.vc-trans-leave-active {
  transition: all 0.25s ease;
}
.vc-trans-enter-active .vc-image-container {
  transition: all 0.5s ease;
  transition-delay: 0.15s;
}
.vc-trans-leave-active .vc-image-container {
  transition: all 0.15s ease;
}
.vc-trans-enter-from,
.vc-trans-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}
.vc-trans-enter-from .vc-image-container,
.vc-trans-leave-to .vc-image-container {
  opacity: 0;
  transform: translateY(1rem);
}
</style>
