<template>
  <div class="main">
    <!-- Testing -->
    <CameraDebugger :app="app" ref="camDebugger" />
    <!-- Loading screen -->
    <div class="loading-container" v-if="showLoadingScreen">
      <div class="loading-text">{{ appProgressPercent }}%</div>
    </div>
    <!-- Instructions -->
    <!-- <div class="instructions-container flex-v-start" v-show="showInstructions"> -->
      <VirteumCard
        class="instructions-container flex-v-start"
        :image="'images/img_karte.png'"
        :title="'Willkommen bei der virtuellen 3D-Kartenansicht von VIRTEUM.'"
        :copy="'Klicke auf Starten, um Dich in die virtuelle 3D Landschaft der Region an der Lippe zu begeben. Mit gedrückter Maustaste könnt ihr euch über das kartierte Gelände bewegen und die kulturhistorischen Hotspots entdecken. Bei mobilen Endgeräten geht das ganz einfach durch ziehen eines Fingers über die Displayoberfläche.<br /> Bei Annäherung an die Hotspots bieten sich diese zur Aktivierung an. Klicke die Hotspots an und es erscheinen die dahinterliegenden Steckbriefe. Diese geben Dir einen kurzen Überblick zur Geschichte, die sich dort verbirgt. Wenn Dich das von Dir aktivierte Thema interessiert, kannst Du vom dessen Steckbrief in den ausführlichen Beitrag wechseln. Dadurch verlässt Du die Kartenansicht und gelangst in die klassisch, strukturierte Darstellung der Geschichte(n) von VIRTEUM. Von dort aus kannst Du jederzeit wieder zurück zur Kartenansicht wechseln.<br /> Wenn Du wissen willst, wo genau Du Dich auf der Karte gerade befindest, dann klicke auf das Google Maps Icon und der Standort wird Dir direkt dort angezeigt.<br />Und jetzt los, viel Spaß beim Navigieren durch die Geschichte(n) der Region an der Lippe.'"
        :visible="showInstructions"
        :showClosingIcon="true"
        :showConfirmButton="false"
        :confirmText="'Start exploring'"
        @closing="closeInstructions"
        @confirmed="closeInstructions"
      />
    <!-- </div> -->
    <!-- START  -->
    <div class="start-icon-container flex-v-start" v-if="showStartscreen">
      <div class="start-confirm-button" @click.stop="startExploring">
        Starten
      </div>
    </div>
    <!-- Main Menu -->
    <div class="menu-container">
      <div class="menu-item" @click="toggleInstructionsVisibility">
        <img
          src="./assets/Info_Icon.svg"
          alt="Button um die Anleitung auszublenden"
          v-show="!showInstructions"
        />
        <img
          src="./assets/Info_Icon_aktiv.svg"
          alt="Button um die Anleitung einzublenden"
          v-show="showInstructions"
        />
      </div>
      <div
        class="menu-item"
        v-show="interactionStarted"
        title="Auf Google Maps zeigen"
        @click.stop="gotoGoogleMaps"
      >
        <img src="./assets/Google_Icon.svg" alt="Auf Google Maps anzeigen" />
      </div>
    </div>
    <!-- Steckbrief -->
    <PoiDetailCard class="poi-card" />
    <!-- 3D canvas -->
    <canvas id="application-canvas" v-show="appStarted"></canvas>
  </div>
</template>

<script>
import { nextTick } from "vue";
import { mapGetters, mapActions } from "vuex";
import PoiDetailCard from "./components/poi/PoiDetailCard.vue";
import VirteumCard from "./components/VirteumCard.vue";
import CameraDebugger from "./components/testanddebug/CameraDebugger.vue";

export default {
  name: "App",
  components: {
    PoiDetailCard,
    CameraDebugger,
    VirteumCard,
  },
  data: () => {
    return {
      showStartscreen: false,
      showInstructions: false,
      appStarted: false,
      appReady: false,
      appProgress: 0,
      markersLoaded: false,
      interactionStarted: false,
      itemSpacing: 0.02,
      mainCamera: null,
      camHeightStart: 6.0,
      camHeightDrag: 3.5,
      geoW: 0,
      geoH: 0,
    };
  },
  methods: {
    ...mapActions(["loadPois", "setActivePoi"]),
    startLoading() {
      this.showStartscreen = false;
      this.showInstructions = false;
      // this.showLoadingScreen = true;
      this.createPcCanvas();
    },
    createPcCanvas() {
      var script = document.querySelector("#pcstartscript");
      if (script) document.body.removeChild(script);
      script = document.createElement("script");
      script.setAttribute("id", "pcstartscript");
      var self = this;
      script.onload = function () {
        //do stuff with the script
        // console.log("script loaded");
        window.pc.script.createLoadingScreen(function (app) {
          self.initApp(app);
        });
      };
      script.src = "__start__.js";
      document.body.appendChild(script);
    },
    initApp(app) {
      this.app = app;
      app.on("preload:progress", (p) => (this.appProgress = p));
      app.on("start", () => {
        // this.showLoadingScreen = false;
      });
      app.on("postinitialize", () => {
        this.appStarted = true;
        this.init3DReferences();
        // setTimeout(this.setup3DElements,100);
        this.setup3DElements();
        if (this.pois.length > 0 && !this.markersLoaded) {
          this.createAllPois();
        }
        this.showStartscreen = true;
        this.appReady = true;
      });

      app.on("app:poi:change", this.onPoiChange, this);
      app.on("lookat:current", this.updateCameraPosition, this);
    },
    init3DReferences() {
      this.mainCamera = this.app.root.findByTag("maincamera")[0];
      this.approot = this.app.root.findByName("Root");
    },
    setup3DElements() {
      //we can edit properties here without re-exporting the pc scene
      this.mainCamera.script.cameraDragging.limitX = new window.pc.Vec2(
        -19.5,
        19.5
      );
      this.mainCamera.script.cameraDragging.limitZ = new window.pc.Vec2(
        -16,
        20
      );
      this.mainCamera.script.cameraDragging.enabled = false;
      window.addEventListener("mouseout", () => {
        this.approot.script.dragMovement.TouchEnd();
      });
      // window.addEventListener("mouseleave", () => {
      //   this.approot.script.dragMovement.TouchEnd();
      // });
    },
    createPoi(data) {
      // console.log("create POI " + data.title);
      var marker = this.app.assets.find("Marker", "template");
      var poiitem = this.app.assets.find("PoiItem", "template");
      if (marker) {
        var m = marker.resource.instantiate();
        this.app.root.addChild(m);
        m.setLocalPosition(data.x, 0, data.z);
        //tweak marker
        m.script.lookAtdistanceHandler.scaleRange = new window.pc.Vec2(
          0.8,
          1.85
        );
        m.script.lookAtdistanceHandler.maxDist = 0.65;
        //add poi items on top of marker
        var poiRoot = m.script.markerDataContext.itemsRoot;
        m.script.markerDataContext.setDataContext(data.items[0]);
        var img = data.items[0].image;
        if (img) {
          var poiIconElement = m.script.markerDataContext.iconElement;
          //load texture as asset and apply to icon element
          var asset = this.app.assets.getByUrl(img);
          if (!asset) {
            this.app.loader.getHandler("texture").crossOrigin = "anonymous";
            //make a new asset
            asset = new window.pc.Asset(img, "texture", {
              // url: '/images/img_karte.png',
              url: img,
              mipmaps: false,
              format: window.pc.PIXELFORMAT_RGB16F,
              // format: window.pc.PIXELFORMAT_R8_G8_B8,
              anisotropy: 1,
              // anisotropy: this.app.graphicsDevice.maxAnisotropy,
              addressU: window.pc.ADDRESS_CLAMP_TO_EDGE,
              addressV: window.pc.ADDRESS_CLAMP_TO_EDGE,
              minFilter: window.pc.FILTER_LINEAR,
              magFilter: window.pc.FILTER_LINEAR,
            });
            this.app.assets.add(asset);
            asset.once("error", function (message) {
              console.error(
                "Error loading texture > " + img + " Error > " + message
              );
            });
            asset.once(
              "load",
              () => {
                var mat = new window.pc.BasicMaterial();
                mat.colorMap = asset.resource;
                // mat.diffuseMap = asset.resource;
                mat.update();
                poiIconElement.render.meshInstances.forEach(
                  (m) => (m.material = mat)
                );
              },
              this
            );
            this.app.assets.load(asset);
          } else {
            // console.log('asset exists');
            var mat = new window.pc.BasicMaterial();
            mat.colorMap = asset.resource;
            mat.update();
            // console.log('2 setting new material on render '+ poiIconElement.render.material);
            poiIconElement.render.meshInstances.forEach(
              (m) => (m.material = mat)
            );
          }
        }

        var h = 0;
        data.items.forEach((i) => {
          var p = poiitem.resource.instantiate();
          poiRoot.addChild(p);
          p.getLocalPosition().y = h;
          // console.log("poi item >> "+JSON.stringify(i));
          p.script.poiItemDataContext.setDataContext(i);
          h +=
            p.script.poiItemDataContext.getDimension().height +
            this.itemSpacing;
        });
      }
    },
    createAllPois() {
      this.markersLoaded = true;
      this.pois.forEach((m) => {
        this.createPoi(m);
      });
      // this.checkEntry();
    },
    onPoiChange(poiData) {
      // console.log('change to poi');
      // console.log('>>'+JSON.stringify(poiData));
      this.setActivePoi(poiData);
      // var queryString = window.location.search;
      // var urlParams = new URLSearchParams(queryString);
      // var entrypoint = urlParams.get("p")
      // if(entrypoint){
      //   urlParams.set("p",poiData.id)
      // }
    },
    async checkEntry() {
      await nextTick();
      var queryString = window.location.search;
      var urlParams = new URLSearchParams(queryString);
      var entrypoint = urlParams.get("p");
      if (entrypoint) {
        // console.log("entry point would be " + entrypoint);
        var entryp = this.poiById(entrypoint);
        // console.log("poi to focus would be " + JSON.stringify(entryp));
        this.$refs.camDebugger.focusedStart(entryp.x, entryp.z);
        this.mainCamera.script.cameraDragging.enabled = true;
        this.showStartscreen = false;
        //hide instructions
        this.showInstructions = false;
        this.interactionStarted = true;
        // this.setActivePoi(entryp.items[0]);
      } else {
        this.$refs.camDebugger.normalStart();
      }
    },
    closeInstructions() {
      this.showInstructions = false;
    },
    startExploring() {
      this.$refs.camDebugger.simulateExplore();
      this.showStartscreen = false;
      this.showInstructions = false;
      this.interactionStarted = true;
      this.mainCamera.script.cameraDragging.enabled = true;
    },

    updateCameraPosition(pos) {
      // console.log('looking at '+JSON.stringify(pos));
      this.geoW = this.mapNum(pos.x, -18.0, 18.0, 7.3221852, 7.63387);
      this.geoH = this.mapNum(pos.z, -19.45, 16.55, 51.767701, 51.572161);
    },
    gotoGoogleMaps() {
      window
        .open(
          "https://www.google.com/maps/@" +
            this.geoH +
            "," +
            this.geoW +
            ",14.5z",
          "gmaps"
        )
        .focus();
    },
    mapNum(val, in_min, in_max, out_min, out_max) {
      return (
        ((val - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
      );
    },
    toggleInstructionsVisibility() {
      this.showInstructions = !this.showInstructions;
    },
  },
  computed: {
    ...mapGetters(["pois", "poiById"]),
    showLoadingScreen() {
      return !this.showStartscreen && !this.appStarted;
    },
    appProgressPercent() {
      return Math.round(this.appProgress * 100);
    },
    enableStart() {
      return this.appStarted;
    },
  },
  mounted() {
    this.startLoading();
    this.loadPois();
  },
  watch: {
    pois: function () {
      // console.log("loaded new pois");
      if (this.appStarted) {
        this.createAllPois();
      }
    },
    appReady: function (n) {
      if (n) {
        // console.log('app ist here...')
        this.checkEntry();
      }
    },
  },
};
</script>

<style>
:root {
  --mg-top: 1.5rem;
  --mg-left: 5rem;
  --img-diam: 4rem;
  --d-width: min(25rem, 90vw);
  --i-width: min(40rem, 90%);
  --fs-400: 16px;
  --co-light: #fff;
  --co-dark: #000;
  --co-mid: #666;
  --co-light-grey: #f2f2f2;
  /* --co-accent: #a80000; */
  --co-accent: #c71311;
  --co-accent-light: #a33838;
  --pa-textbox: 4.5em 1em 2.5em 1em;
  --btn-radius: 0rem;
  --btn-border-width: 1px;
  --btn-border-style: solid;
  --btn-padding: 1rem 1.5rem;
}
html {
  height: 100%;
  width: 100%;
  background-color: #d7d7d7;
  font-size: var(--fs-400);
}
body {
  margin: 0;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #d7d7d7;
  /* background-image: url('data/start_blurred.jpg'); */
  background-size: cover;
  background-position: 50% 50%;
  font-family: Gilroy, Helvetica, arial, sans-serif;
  position: relative;
  width: 100%;

  -webkit-tap-highlight-color: transparent;
}
#application-canvas {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: transparent; */
}
#application-canvas.fill-mode-NONE {
  margin: auto;
}
#application-canvas.fill-mode-KEEP_ASPECT {
  width: 100%;
  height: auto;
  margin: 0;
}
#application-canvas.fill-mode-FILL_WINDOW {
  width: 100%;
  height: 100%;
  margin: 0;
}
canvas:focus {
  outline: none;
}
.flex-v-center {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.flex-v-start {
  display: flex;
  flex-flow: column;
  align-items: center;
}
/* POI */
.poi-card {
  z-index: 10;
  position: absolute;
  bottom: 0px;
  left: min(1em, 0.5%);
  max-height: calc(100vh - 7.5rem);
}
/* LOADING */
.loading-container {
  position: absolute;
  z-index: 99;
  bottom: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.loading-text {
  color: var(--co-accent);
  font-size: 2rem;
  width: min(40rem, 90%);
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: calc(100vh - 25rem);
  overflow-y: auto;
}
.instructions-container {
  position: absolute;
  z-index: 98;
  top:10vh;
  /* bottom: 2.5rem; */
  left:  max(2rem , calc(50vw - 30rem));
  right: max(2rem , calc(50vw - 30rem));
  max-height: 80vh;
}
.intro-card {
  /* width: var(--i-width); */
}
.start-icon-container {
  position: absolute;
  z-index: 90;
  /* top: 25vh; */
  bottom: 2.5rem;
  left: 10%;
  right: 10%;
  color: var(--co-accent);
}
.start-confirm-button {
  user-select: none;
  cursor: pointer;
  background-color: var(--co-accent);
  color: var(--co-light);
  padding: var(--btn-padding);
  margin-top: 1em;
  font-size: 1.5em;
}
.triangle-icon {
  cursor: pointer;
  user-select: none;
}
.triangle-text {
  cursor: pointer;
  user-select: none;
  font-weight: 900;
  font-size: 1.4em;
  margin-bottom: 0.4em;
}
/* Main menu */
.menu-container {
  position: absolute;
  left: 3em;
  bottom: min(3em, 10%);
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-start;
  gap: 1.25em;
  z-index: 9;
}
.menu-item {
  display: flex;
  cursor: pointer;
  user-select: none;
}
.menu-container > .menu-item {
  width: 2.5em;
  height: 2.5em;
}
.icon-maps {
  user-select: none;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  :root {
    --btn-padding: 0.5rem 0.75rem;
  }
  html{
    font-size: 12px;
  }
  .menu-container {
    bottom: 1.25em;
    left: 1.25em;
    gap: 0.875em;
  }
  .menu-container > .menu-item {
    width: 1.75em;
    height: 1.75em;
  }
}
@media screen and (max-height: 500px) {
  :root {
    --btn-padding: 0.5rem 0.75rem;
  }
  html{
    font-size: 12px;
  }
  .menu-container {
    bottom: 1.25em;
    left: 1.25em;
    gap: 0.875em;
  }
  .menu-container > .menu-item {
    width: 1.75em;
    height: 1.75em;
  }
}
</style>