<template>
  <transition name="vc-trans" :duration="{ enter: 750, leave: 150 }">
  <div class="virteum-card" v-if="visible">
        <div class="vc-close-icon" @click.stop="$emit('closing')" v-if="showClosingIcon">
          <img src="@/assets/close-icon.svg" alt="" />
        </div>
        <!-- <div class="vc-image-container" >
          <img :src="image" alt="" class="vc-image" />
        </div> -->
        <div class="vc-text-container">
          <h1>{{ title }}</h1>
          <div v-html="copy"></div>
        </div>
        <div class="vc-confirm-button" @click.stop="$emit('confirmed')" v-if="showConfirmButton">
            {{ confirmText }}
      </div>
  </div>
  </transition>
</template>

<script>
export default {
  emits: ["closing","confirmed"],
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    copy: {
      type: String,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    showClosingIcon:{
        type: Boolean,
        default(){
            return true;
        }
    },
    showConfirmButton:{
        type: Boolean,
        default(){
            return false;
        }
    },
    confirmText: {
      type: String,
    },
  },
};
</script>

<style scoped>
.virteum-card {
  /* border: 1px solid plum; */
  padding:0;
  margin:0;
  display: flex;
  flex-flow: column;
  align-items: center;
  /* position: relative; */
}
@media screen and (max-height:375px) {
  .vc-close-icon {
    font-size: 10px;
  }
}

.vc-close-icon {
  position: absolute;
  top: 2.375rem;
  right: 2.25rem;
  z-index: 3;
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
  background-color: var(--co-light-grey);
  padding: 0.78125rem;
  border-radius: 1.40625rem;
}
.vc-text-container {
  background-color: var(--co-light,#fff)  ;
  padding: var(--pa-textbox);
  color: var(--co-dark,#000);
  border-radius: 2.5rem;
  z-index: 0;
  font-size: 1.0rem;
  line-height: 1.25rem;
  overflow-y: auto;
  overflow-x: hidden;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.vc-text-container > h1 {
  color: #000;
  font-size: 1.125rem;
  font-weight: 900;
  /* text-transform: uppercase; */
}
.vc-image {
  object-fit: cover;
  border-radius: 4em;
  width: 8em;
  height: 8em;
}
.vc-image-container {
  z-index: 3;
}
.vc-confirm-button {
  user-select: none;
  cursor: pointer;
  background-color: var(--co-accent);
  color: var(--co-light);
  padding: var(--btn-padding);
  margin-top: 1em;
}

/* Transition */
.vc-trans-enter-active,
.vc-trans-leave-active {
  transition: all 0.25s ease;
}
.vc-trans-enter-active .vc-image-container{
    transition: all 0.5s ease;
    transition-delay: 0.15s;
}
.vc-trans-leave-active .vc-image-container {
  transition: all 0.15s ease;
}
.vc-trans-enter-from,
.vc-trans-leave-to {
  opacity: 0;
  transform: translateY(1em);
}
.vc-trans-enter-from .vc-image-container,
.vc-trans-leave-to .vc-image-container {
  opacity: 0;
  transform: translateY(1em);
}
</style>
