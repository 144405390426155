<template>
  <div class="cam-test-container"></div>
</template>

<script>
export default {
  props: ["app"],
  expose: ["focusedStart", "normalStart", "simulateExplore"],
  methods: {
    setHigh() {
      var mainCamera = this.app.root.findByTag("maincamera")[0];
      // if (mainCamera.camera.aspectRatio < 1) {
      if (window.innerHeight < window.innerWidth) {
        // console.log("fov HOR");
        mainCamera.script.cameraDragging.h = 80;
        mainCamera.script.cameraDragging.targetPosition = new window.pc.Vec3(
          0,
          80,
          1
        );
      } else {
        // console.log("fov VER");
        mainCamera.script.cameraDragging.h = 80;
        mainCamera.script.cameraDragging.targetPosition = new window.pc.Vec3(
          0,
          175,
          1
        );
      }

      this.pcApp.lookatOffset = new window.pc.Vec3(0, 0, -8.0);
    },
    setLow() {
      var mainCamera = this.app.root.findByTag("maincamera")[0];
      mainCamera.script.cameraDragging.h = 1.5;
      mainCamera.script.cameraDragging.targetPosition = new window.pc.Vec3(
        6,
        1.5,
        3.75
      );
      this.pcApp.lookatOffset = new window.pc.Vec3(0, 0, -2.0);
    },
    toggleBokeh() {
      var mainCamera = this.app.root.findByTag("maincamera")[0];
      mainCamera.script.bokeh.enabled = !mainCamera.script.bokeh.enabled;
    },
    toggleRaycasting() {
      this.pcApp.stopRaycastPosition = !this.pcApp.stopRaycastPosition;
    },
    toggleAlpha() {
      var mat = this.app.assets.find("Google-earth-Map", "material");
      // var mat = this.app.assets.find("OverviewMapMaterial", "material");
      var opacity = { value: mat.resource.opacity };
      this.app
        .tween(opacity)
        .to({ value: 1.0 - opacity.value }, 1, window.pc.SineOut)
        .on(
          "update",
          function () {
            mat.resource.opacity = opacity.value;
            mat.resource.update();
          }.bind(this)
        )
        .start();
    },
    toggleLayerOverview() {
      var mainCamera = this.app.root.findByTag("maincamera")[0];
      console.log("camlayers: " + mainCamera.camera.layers);
      if (mainCamera.camera.layers.includes(1001)) {
        mainCamera.camera.layers = [0, 1, 2, 3, 1002, 1003];
      } else {
        mainCamera.camera.layers = [0, 1, 2, 3, 1000, 1001, 1002, 1003];
      }
    },
    simulateStart() {
      var mainCamera = this.app.root.findByTag("maincamera")[0];
      mainCamera.script.vignette.darkness = 3;
      var exploreRoot = this.app.root.findByTag("exploreroot")[0];
      exploreRoot.enabled = false;
      this.pcApp.stopRaycastPosition = true;
      var mat = this.app.assets.find("Google-earth-Map Copy", "material");
      var tweenValues = {
        aperture: mainCamera.script.bokeh.aperture,
        alpha: mat.resource.opacity,
      };
      this.app
        .tween(tweenValues)
        .to(
          {
            aperture: 0.01,
            alpha: 1.0,
          },
          1,
          window.pc.Sineout
        )
        .on(
          "update",
          function () {
            mainCamera.script.bokeh.aperture = tweenValues.aperture;
            mat.resource.opacity = tweenValues.alpha;
            mat.resource.update();
          }.bind(this)
        )
        .on(
          "complete",
          function () {
            // console.log('complete');
          }.bind(this)
        )
        .start();
      this.setHigh();
    },
    simulateExplore() {
      var mainCamera = this.app.root.findByTag("maincamera")[0];
      var exploreRoot = this.app.root.findByTag("exploreroot")[0];
      exploreRoot.enabled = true;
      // var rings = this.app.root.findByTag("virteumrings")[0];
      // rings.enabled = true;
      var mat = this.app.assets.find("Google-earth-Map Copy", "material");
      mainCamera.camera.layers = [0, 1, 2, 3, 1000, 1002, 1003];
      var tweenValues = {
        aperture: mainCamera.script.bokeh.aperture,
        alpha: mat.resource.opacity,
        rot: mainCamera.getLocalEulerAngles().x,
      };
      this.app
        .tween(tweenValues)
        .to(
          {
            aperture: 0.7,
            alpha: 0,
            rot: -30,
          },
          3.2,
          window.pc.SineOut
        )
        .on(
          "update",
          function () {
            mainCamera.script.bokeh.aperture = tweenValues.aperture;
            mainCamera.setLocalEulerAngles(tweenValues.rot, 0, 0);
            mat.resource.opacity = tweenValues.alpha;
            mat.resource.update();
          }.bind(this)
        )
        .on(
          "complete",
          function () {
            // console.log('complete explore');
            this.pcApp.stopRaycastPosition = false;
            mainCamera.camera.layers = [0, 1, 2, 3, 1002, 1003];
          }.bind(this)
        )
        .start();
      this.setLow();
    },
    enableStartClick() {
      this.app.mouse.once(
        window.pc.EVENT_MOUSEDOWN,
        this.simulateExplore,
        this
      );
      if (this.app.touch)
        this.app.touch.once(
          window.pc.EVENT_TOUCHSTART,
          this.simulateExplore,
          this
        );
    },
    normalStart() {
      // this.enableStartClick();
      // this.toggleBokeh();

      this.simulateStart();
    },
    focusedStart(x, z) {
      this.pcApp.stopRaycastPosition = true;
      // console.log('starting with focus on '+x+':'+z);
      var mainCamera = this.app.root.findByTag("maincamera")[0];
      var exploreRoot = this.app.root.findByTag("exploreroot")[0];
      exploreRoot.enabled = true;
      var mat = this.app.assets.find("Google-earth-Map Copy", "material");
      mainCamera.camera.layers = [0, 1, 2, 3, 1000, 1002, 1003];
      // var rings = this.app.root.findByTag("virteumrings")[0];
      // rings.enabled = true;
      var tweenValues = {
        aperture: mainCamera.script.bokeh.aperture,
        alpha: mat.resource.opacity,
        rot: mainCamera.getLocalEulerAngles().x,
      };
      this.app
        .tween(tweenValues)
        .to(
          {
            aperture: 0.7,
            alpha: 0,
            rot: -30,
          },
          2.7,
          window.pc.QuartOut
        )
        .on(
          "update",
          function () {
            mainCamera.script.bokeh.aperture = tweenValues.aperture;
            mat.resource.opacity = tweenValues.alpha;
            mainCamera.setLocalEulerAngles(tweenValues.rot, 0, 0);
            mat.resource.update();
          }.bind(this)
        )
        .on(
          "complete",
          function () {
            // console.log('complete explore');
            this.pcApp.stopRaycastPosition = false;
            mainCamera.camera.layers = [0, 1, 2, 3, 1002, 1003];
          }.bind(this)
        )
        .start();
      mainCamera.script.cameraDragging.h = 1.5;
      mainCamera.script.cameraDragging.targetPosition = new window.pc.Vec3(
        Number.parseFloat(x),
        1.5,
        Number.parseFloat(z) + 1
      );
      this.pcApp.lookatOffset = new window.pc.Vec3(0, 0, -2.0);
    },
  },
  computed: {
    pcApp() {
      return this.app;
    },
  },
  mounted() {
    // console.log('mounted camDebugger');
  },
};
</script>

<style scoped>
.cam-test-container {
  position: absolute;
  top: 0.1rem;
  left: 0.1rem;
  z-index: 101;
  /* background-color: bisque; */
  display: flex;
  flex-wrap: wrap;
}
</style>